import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Text, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import queryString from 'query-string'
import ScaleLoader from "react-spinners/ScaleLoader";

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Header } from '../components/Header';
import { EasySteps } from '../components/EasySteps';
import { swiperParams } from '../theme';
import Container from '../components/container';
import { Hide } from '../components/hide';
import { Map } from '../components/Map';
import { Button } from '../components/Button';
import Alert from '../components/Alert';
import {
  updateMarkersWithDistances,
  retrievePickupPoint,
} from '../components/Map/Functions';



const Input = styled.input`
  width: 100%;
  height: 38px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightBlueGrey};
  background-color: #ffffff;
`;

const FIND_BOX = gql`
  query FindBox($boxId: ID!) {
    findBoxByID(id: $boxId) {
      _id
      pickupPoint {
        name
        lat
        lng
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
      }
    }
  }
`;

export default function PickYourBoxPage(props) {
  const [boxId, setBoxId] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [destination, setDestination] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [mapApis, setMapApis] = useState({ map: null, maps: null });
  const [alertMsg, setAlertMsg] = useState(null);
  
  const { t } = useTranslation();

  const [findBox, { loading, data }] = useLazyQuery(FIND_BOX, {
    onCompleted: data => {
      if (data.findBoxByID) {
        setDestination(data.findBoxByID.pickupPoint);
        // setMarkers(retrievePickupPoint(boxId, userLocation));
        setMarkers([data.findBoxByID.pickupPoint]);
        updateMarkersWithDistances(
          mapApis.map,
          mapApis.maps,
          [data.findBoxByID.pickupPoint],
          userLocation, // passed as destination
          userLocation,
          updateState
        );
      }else{
        setAlertMsg(t('box_tracking.boxid_not_found'))
      }
    },
    onError: (e)=>{
      setAlertMsg(t('box_tracking.boxid_not_found'))
    }
  });

  //Locate Button functionality
  const locate = () => {
   
    console.log('Locating box...');
    if (boxId) {
      setAlertMsg(null);
      findBox({ variables: { boxId } });
    }
  };

  const updateState = (markers, destination, userLocation) => {
    setMarkers(markers);
    setDestination(destination);
    setUserLocation(userLocation);
  };

  const onApiLoaded = (map, maps, markers, destination, userLocation) => {
    setMapApis({
      map: map,
      maps: maps,
    });
    // updateState(markers, destination, userLocation);
    setUserLocation(userLocation);
  };

  const query = props.location.search ? queryString.parse(props.location.search): {}
  if(query.boxId && !boxId){
    setBoxId(query.boxId)
  }

  return (
    <Layout {...props}>
      <SEO title="PICK YOUR BOX" keywords={['weboxit', 'boxit']} />
      <Header
        title={t('pick_your_box.title')}
        subtitle={t('pick_your_box.subtitle')}
      />
      <Container>
        <Box py="32px">
          <Text
            fontSize="15px"
            lineHeight="1.64"
            letterSpacing="0.7px"
            color="marine"
          >
            {t('pick_your_box.where_is')}
          </Text>
          <Alert message={alertMsg} />
          <Hide desktop>
            <Flex
              marginTop="15px"
              marginBottom="15px"
              width={1}
              flexDirection="column"
            >
              <Input
                id="locate-input-mobile"
                type="text"
                flex={1}
                defaultValue={query.boxId ? query.boxId : ''}
                placeholder={t('pick_your_box.please_enter')}
                onChange={e => setBoxId(e.target.value)}
              />
              { loading ?
                  <ScaleLoader
                  css={{}}
                  size={20}
                  color={"#01549b"}
                  loading={true}
                  /> :
                  <Button
                    id="search-button"
                    flex={1}
                    fontSize="12px"
                    height="38px"
                    marginTop="-10px"
                    onClick={locate}
                  >
                    {t('pick_your_box.locate_pickup')}
                  </Button>
              }
            </Flex>
            <Map
              height="280px"
              isMobile="true"
              fullWidthMap={true}
              showUserLocation={true}
              markersExternal={markers}
              onApiLoaded={onApiLoaded}
            />
          </Hide>

          <Hide mobile tablet>
            <Flex marginTop="15px" width={1}>
              <Box width="350px" marginRight={['auto', 'auto', '11px']}>
                <Input
                  id="locate-input"
                  type="text"
                  defaultValue={query.boxId ? query.boxId : ''}
                  placeholder={t('pick_your_box.please_enter')}
                  onChange={e => setBoxId(e.target.value) }
                />
              </Box>
              { loading ? 
                <ScaleLoader
                css={{}}
                size={20}
                color={"#01549b"}
                loading={true}
                /> :
                <Button
                  id="search-button"
                  height="38px"
                  px="24px"
                  marginTop="0"
                  onClick={locate}
                >
                  {t('pick_your_box.locate_pickup')}
                </Button>
              }
            </Flex>
            <Map
              height="400px"
              fullWidthMap={true}
              showInfoWindowInsideMap={true}
              showUserLocation={true}
              markersExternal={markers}
              onApiLoaded={onApiLoaded}
            />
          </Hide>
        </Box>
      </Container>
      <EasySteps params={swiperParams} />
    </Layout>
  );
}
