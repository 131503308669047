import React from 'react';
import { Text, Box, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

const InfoWindowItem = ({
  marker,
  isSelected,
  isHovered,
  showShippingCosts,
  order,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      padding="9px 15px 0 15px"
      style={{ borderBottom: 'solid 1px #cfd4dc', cursor: 'pointer' }}
      onClick={onClick}
      backgroundColor={
        isSelected && !isHovered
          ? 'maize'
          : isHovered
          ? 'blues.veryLight'
          : 'white'
      }
    >
      <Flex>
        <Text
          fontSize={['16px', '16px', '16px']}
          fontWeight="bold"
          letterSpacing="0.3px"
          color={isHovered ? 'dustyGreen' : 'blues.twilight'}
        >
          {order ? order + '. ' : ''}
          {marker.name}
        </Text>
        {showShippingCosts && (
          <Text
            flex={1}
            fontSize={['16px', '16px', '16px']}
            fontWeight="bold"
            letterSpacing="0.3px"
            textAlign="right"
            color={isHovered ? 'dustyGreen' : 'blues.twilight'}
            style={{ whiteSpace: 'nowrap' }}
          >
            +{marker.additionalCost} {t('euro')}
          </Text>
        )}
      </Flex>
      <Text
        marginLeft={['0', '20px']}
        marginTop="-2px"
        marginBottom="4px"
        fontSize={['13px', '13px', '13px']}
        fontWeight={isSelected && !isHovered ? 'bold' : ''}
        letterSpacing="0.6px"
        lineHeight="19px"
        color="marine"
      >
        {marker.distanceText && (
          <span>
            {marker.distanceText}
            <br />
          </span>
        )}
        {marker.address && (
          <span>
            {marker.address.street}, {marker.address.city}
            <br />
          </span>
        )}
        {marker.contact && (
          <span>
            {t('map.telephone')} {marker.contact.phone}
          </span>
        )}
      </Text>
    </Box>
  );
};

export default function InfoWindow({
  markers,
  homeMarker,
  selectedMarker,
  hoveredMarker,
  handleItemClick,
  insideMap,
  height,
  splitAt,
  showShippingCosts,
  ...props
}) {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      backgroundColor="white"
      style={{
        width: insideMap ? (splitAt ? splitAt : '35%') : '100%',
        height: height ? height : '400px',
        position: insideMap ? 'relative' : '',
        opacity: '0.82',
        marginTop: insideMap ? (height ? -height : '-399px') : 0,
        marginLeft: insideMap ? (splitAt ? splitAt : '65%') : props.marginLeft,
        borderRadius: '4px',
        border: 'solid 1px #cccccc',
        overflowY: 'auto',
      }}
    >
      {homeMarker && (
        <InfoWindowItem
          marker={homeMarker}
          isSelected={homeMarker === selectedMarker}
          isHovered={homeMarker === hoveredMarker}
          showShippingCosts={showShippingCosts}
          order=""
          key={'InfoWindowItemHome'}
          onClick={e => handleItemClick(e, homeMarker)}
        />
      )}
      {markers &&
        markers.map((marker, key) => (
          <InfoWindowItem
            marker={marker}
            isSelected={marker === selectedMarker}
            isHovered={marker === hoveredMarker}
            showShippingCosts={showShippingCosts}
            order={key + 1}
            key={'InfoWindowItem' + marker.lat + marker.lng + key}
            onClick={e => handleItemClick(e, marker)}
          />
        ))}
      {!markers && (
        <Box
          width="100%"
          padding="9px 15px 0 15px"
          style={{ borderBottom: 'solid 1px #cfd4dc', cursor: 'pointer' }}
        >
          <Text
            marginTop="15px"
            marginBottom="20px"
            fontSize={['13px', '13px', '13px']}
            letterSpacing="0.6px"
            lineHeight="19px"
            color="marine"
            textAlign="center"
          >
            {t('map.no_results_found')}
          </Text>
        </Box>
      )}
    </Flex>
  );
}
