
// import stations from './stations-data';

const MAX_PICKUP_POINTS_RETURNED = 10;

/* 
    Data extracted using the script bellow
    from website http://www.aegeanoil.com/gas/find-a-pickupPoint-979.htm?lang=el 
    filtered only for Attiki on 09/19

    $('.stores-list li').map(i =>
    {
      var item = $('.stores-list li')[i];
      var result = {
      title: item.children[0] ? item.children[0].innerText.trim() : 'n/a',
      address: item.children[2] ? item.children[2].innerText.trim() : 'n/a',
      contact: item.children[3] ? item.children[3].innerText.trim() : 'n/a',
      lat: item.children[5] ? item.children[5].innerText.trim().split(',')[0] : 'n/a',
      lng: item.children[5] ? item.children[5].innerText.trim().split(',')[1] : 'n/a',
      };
      return result;
    })
 */

const haversineDistance = (lat1, lng1, lat2, lng2) => {
  function toRad(x) {
    return (x * Math.PI) / 180;
  }

  var R = 6371;
  var x1 = lat2 - lat1;
  var dLat = toRad(x1);
  var x2 = lng2 - lng1;
  var dLng = toRad(x2);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;

  
  return d;
};

const nearestPickupPoints = (lat, lng) => {
  if(!window.stations){
    return []
  }
  // const pickupPointsWithApproximateDistance = window.stations.map(station => {
  //      return {
  //     ...station,
  //     distance: haversineDistance(lat, lng, station.lat, station.lng),
  //   };
  // });

  // const ddd =  window.stations.filter((station)=>{
  //   return haversineDistance(lat, lng, station.lat, station.lng) < 5 
  // })

  const pickupPointsWithApproximateDistance =  window.stations.reduce((init, station)=>{
    const dist = haversineDistance(lat, lng, station.lat, station.lng)
    if(dist < 10)
    {
      return [...init, { ...station, distance: dist}]
    }
    return init
  },[])

  pickupPointsWithApproximateDistance.sort((a, b) => a.distance - b.distance);

  //console.log('pickupPointsWithApproximateDistance sort', pickupPointsWithApproximateDistance.sort((a, b) => a.distance - b.distance))

  // console.log(pickupPointsWithApproximateDistance.slice(
  //   0,
  //   MAX_PICKUP_POINTS_RETURNED
  // ))

  return pickupPointsWithApproximateDistance.slice(
    0,
    MAX_PICKUP_POINTS_RETURNED
  );
  // var results = [];
  // for (var i = 0; i < MAX_PICKUP_POINTS_RETURNED; i++) {
  //   var pickupPoint = pickupPointsWithApproximateDistance[i];
  //   pickupPoint.contact = formatContact(pickupPoint.contact);
  //   results.push(pickupPoint);
  // }
  // return results;
};

const formatContact = phone => {
  phone = phone.replace(/\D/g, '').slice(0, 10);

  if (phone.length === 10) {
    phone = phone
      .substring(0, 3)
      .concat(' ')
      .concat(phone.substring(3, 10));
  }

  return phone;
};

export { nearestPickupPoints, haversineDistance };
