import React from 'react';
import { Text, Box, Flex, Image } from 'rebass';
import { Button } from '../Button';
import { Hide } from '../hide';
import GoogleMapReact from 'google-map-react';
import InfoWindow from './InfoWindow';
import SearchBox from './SearchBox';
import {
  fitBounds,
  addPlaces,
  updateMarkersWithDistances,
  search,
} from './Functions';
import { withTranslation, useTranslation } from 'react-i18next';

import MapMarkerPointSVG from './images/map-marker-point.svg';
import MapMarkerUserLocationSVG from './images/map-marker-user.svg';

const mapOptions = {
  bootstrapURLKeys: {
    key: 'AIzaSyDzrA178KC8bCxnI7iogrDZmgERZ7b1hYI',
    // key: 'AIzaSyBc0TB6CAEOoReL1RklYGUIKn3Z7_2GnJg',

    // key: 'AIzaSyAex1airxBbP0ws_m-6ak7PZ1w3k0voVTs',
    libraries: ['places', 'geometry'],
  },
  options: {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
  },
  defaultCenter: { lat: 38.013965, lng: 23.827759 },
  defaultZoom: 15,
};

/* Google Map React */
export const Marker = ({
  marker,
  isSelected,
  isHovered,
  isHome,
  showShippingCosts,
  order,
  onClick,
  onMouseOver,
  onMouseOut,
  isUserLocation = order === '',
}) => {
  const { t } = useTranslation();

  return (
    <Flex width={showShippingCosts ? '240px' : '220px'}>
      <Box
        marginLeft={isUserLocation ? '-10px' : '-19px'}
        marginTop={isUserLocation ? '-10px' : '-38px'}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <Image
          width={isUserLocation ? '20px' : '38px'}
          src={isUserLocation ? MapMarkerUserLocationSVG : MapMarkerPointSVG}
          alt="weboxit point"
        />
        {!isUserLocation && (
          <Text
            width="38px"
            marginTop="-33px"
            textAlign="center"
            fontSize="16px"
            fontWeight={isSelected ? '900' : 'bold'}
            color={isSelected ? 'dustyGreen' : 'pastelRed'}
          >
            {order}
          </Text>
        )}
      </Box>
      {isHovered &&
        !isHome &&
        marker.address &&
        (marker.distanceText || marker.contact) && (
          <Box
            padding="9px 15px 0 15px"
            marginLeft="15px"
            marginTop="-30%"
            backgroundColor="white"
            style={{
              border: 'solid 1px #cfd4dc',
              borderRadius: '4px',
              zIndex: '9999',
            }}
          >
            <Flex>
              {marker.title && (
                <Text
                  fontSize={['12px', '12px', '12px']}
                  fontWeight="bold"
                  letterSpacing="0.3px"
                  color="blues.twilight"
                >
                  {marker.title}
                </Text>
              )}
              {showShippingCosts && (
                <Text
                  flex={1}
                  fontSize={['12px', '12px', '12px']}
                  fontWeight="bold"
                  letterSpacing="0.3px"
                  textAlign="right"
                  color={isHovered ? 'dustyGreen' : 'blues.twilight'}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  +{marker.additionalCost} {t('euro')}
                </Text>
              )}
            </Flex>
            <Text
              marginBottom="4px"
              fontSize={['10px', '10px', '10px']}
              letterSpacing="0.6px"
              lineHeight="1.5"
              color="marine"
            >
              {marker.distanceText}
              <br />
              {marker.address}
              <br />
              {marker.contact && t('map.telephone').concat(marker.contact)}
            </Text>
          </Box>
        )}
      {isHovered && isHome && (
        <Box marginTop="-12px">
          <Box
            padding="5px"
            marginLeft="4px"
            height="20px"
            width="136px"
            backgroundColor="blues.medium"
            style={{
              height: '20px !important',
              border: 'solid 1px #ffffff',
              borderRadius: '10px',
              zIndex: '9999',
            }}
          >
            <Text
              fontSize={['10px', '10px', '10px']}
              width="100%"
              textAlign="center"
              letterSpacing="0"
              color="white"
            >
              {t('map.home_delivery_extra_cost')}
            </Text>
          </Box>
        </Box>
      )}
    </Flex>
  );
};

class Map extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    var destination = null;
    if (props.boxitData) {
      destination = {
        name: t('map.home_delivery_title'),
        contact: {
          address: { city: props.boxitData.pickup.address },
          phone: props.boxitData.pickup.phone,
        },
        lat: props.boxitData.pickup.lat,
        lng: props.boxitData.pickup.lng,
        additionalCost: props.boxitData.pickup.additionalCost.toFixed(2),
      };
    }

    this.state = {
      loading: true,
      mapsApiLoaded: false,
      map: null,
      maps: null,
      markers: this.props.markersExternal ? this.props.markersExternal : null,
      markersExternal: this.props.markersExternal,
      destination: props.destination ? props.destination : destination,
      userLocation: null,
      showUserLocation: props.showUserLocation ? props.showUserLocation : false,
      autoLoadPickupPoints: props.autoLoadPickupPoints
        ? props.autoLoadPickupPoints
        : false,
      isMobile: props.isMobile ? props.isMobile : false,
      showInfoWindow: props.showInfoWindow ? props.showInfoWindow : true,
      showInfoWindowInsideMap: props.showInfoWindowInsideMap
        ? props.showInfoWindowInsideMap
        : false,
      fullWidthMap: props.fullWidthMap ? props.fullWidthMap : false,
      selectedMarker: props.selectedMarker,
      hoveredMarker: props.hoveredMarker,
      showSearchBox: props.showSearchBox,
      searchBoxPlaceholder: props.searchBoxPlaceholder,
      onApiLoadedExternal: props.onApiLoaded,
      boxitData: props.boxitData,
      showShippingCosts: props.showShippingCosts
        ? props.showShippingCosts
        : false,
      updateShippingCost: props.updateShippingCost,
      defaultSearchValue: props.defaultSearchValue,
    };
  }

  // Update state after Google Maps API is loaded
  apiIsLoaded = (
    map,
    maps,
    markers,
    destination,
    onApiLoadedExternal = null
  ) => {
    this.setState({
      mapsApiLoaded: true,
      map: map,
      maps: maps,
    });

    if (destination && (destination.lat == null || destination.lng == null)) {
      console.log(
        `Trying to geocode destination '${destination.contact.address.city}'...`
      );
      const geocoder = new maps.Geocoder();
      geocoder.geocode(
        { address: destination.contact.address.city },
        (results, status) => {
          if (status === maps.GeocoderStatus.OK) {
            destination.lat = results[0].geometry.location.lat();
            destination.lng = results[0].geometry.location.lng();
            addPlaces(
              this.state.map,
              this.state.maps,
              [],
              destination,
              this.state.userLocation,
              this.updateState.bind(this)
            );
          } else {
            alert(
              'Geocode was not successful for the following reason: ' + status
            );
          }
        }
      );
    }

    if (
      this.state.mapsApiLoaded &&
      this.state.showUserLocation &&
      this.state.userLocation === null
    ) {
      console.log('Trying to get user location...');
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          this.setState({
            userLocation: {
              lat: latitude,
              lng: longitude,
            },
            loading: false,
          });
          if (this.state.map) {
            this.state.map.panTo({ lat: latitude, lng: longitude });
          }
          if (this.state.autoLoadPickupPoints) {
            addPlaces(
              this.state.map,
              this.state.maps,
              [],
              destination,
              this.state.userLocation,
              this.updateState.bind(this)
            );
          }

          if (onApiLoadedExternal) {
            onApiLoadedExternal(
              this.state.map,
              this.state.maps,
              this.state.markers,
              destination,
              this.state.userLocation
            );
          }
        },
        () => {
          console.error('Unable to get location');
        }
      );
    } else {
      if (this.state.autoLoadPickupPoints) {
        console.log('Trying to addPlaces');
        addPlaces(
          this.state.map,
          this.state.maps,
          [],
          destination,
          this.state.userLocation,
          this.updateState.bind(this)
        );
      } else {
        updateMarkersWithDistances(map, maps, markers, destination);
        fitBounds(map, maps, markers, destination);
        this.setState({
          markers: markers,
        });
      }
    }
  };

  updateState(markers, destination, userLocation) {
    this.setState({
      markers: markers,
      destination: destination,
      userLocation: userLocation,
    });
  }

  handleMarkerClick(e, selectedMarker) {
    this.state.map.panTo(
      new this.state.maps.LatLng(selectedMarker.lat, selectedMarker.lng)
    );
    this.setState({ selectedMarker: selectedMarker });

    if (this.props.updateShippingCost) {
      this.props.updateShippingCost(selectedMarker.additionalCost);
    }
    this.props.onMarkerSelected && this.props.onMarkerSelected(selectedMarker);
  }

  handleMarkerMouseOver(e, hoveredMarker) {
    if (this.state.hoveredMarker !== hoveredMarker) {
      this.setState({ hoveredMarker: hoveredMarker });
    }
  }

  handleMarkerMouseOut(e, hoveredMarker) {
    if (this.state.hoveredMarker) {
      this.setState({ hoveredMarker: null });
    }
  }

  componentDidMount(props) {}

  render() {
    const {
      map,
      maps,
      markersExternal,
      mapsApiLoaded,
      loading = true,
      destination,
      userLocation,
      isMobile,
      showUserLocation,
      autoLoadPickupPoints,
      showInfoWindow,
      showInfoWindowInsideMap,
      fullWidthMap,
      selectedMarker,
      hoveredMarker,
      showSearchBox,
      searchBoxPlaceholder,
      onApiLoadedExternal,
      boxitData,
      showShippingCosts,
      updateShippingCost,
      defaultSearchValue,
    } = this.state;

    const markers = this.props.markersExternal
      ? this.props.markersExternal
      : this.state.markers;

    return (
      <>
        {mapsApiLoaded && showSearchBox && (
          <Flex marginTop="15px">
            <Box width={this.props.splitAt ? this.props.splitAt : '100%'}>
              <Flex flexDirection="row" flexWrap="wrap" pb={['18px', 0, 0]}>
                <Box width={[1, '60%', '64%']} mr={['0', '11px', '11px']}>
                  <SearchBox
                    map={map}
                    maps={maps}
                    defaultValue={defaultSearchValue}
                    destination={destination}
                    addPlaces={addPlaces}
                    updateState={this.updateState.bind(this)}
                    searchBoxPlaceholder={searchBoxPlaceholder}
                  />
                </Box>
                {this.props.searchButtonText && (
                  <Box flex={1}>
                    <Button
                      id="search-button"
                      width="100%"
                      height="37px"
                      paddingX="15px"
                      marginTop="0"
                      fontSize="14px"
                      onClick={search}
                    >
                      {this.props.searchButtonText}
                    </Button>
                  </Box>
                )}
              </Flex>
            </Box>
            {this.props.chooseLabelText && !this.props.fullWidthMap && (
              <Text
                marginLeft="20px"
                fontSize="16px"
                fontWeight="bold"
                lineHeight="38px"
                letterSpacing="1px"
                color="blues.peacock"
              >
                {this.props.chooseLabelText}
              </Text>
            )}
          </Flex>
        )}

        <Flex
          width={'100%'}
          marginBottom={['20px', '20px', '53px']}
          style={{ height: this.props.height ? this.props.height : '400px' }}
        >
          <Box
            marginBottom="53px"
            width={
              isMobile || fullWidthMap
                ? '100%'
                : this.props.splitAt
                ? this.props.splitAt
                : '65%'
            }
            style={{
              height: this.props.height ? this.props.height : '400px',
              borderRadius: '4px',
              border: 'solid 1px #cccccc',
            }}
          >
            <GoogleMapReact
              bootstrapURLKeys={mapOptions.bootstrapURLKeys}
              center={
                this.props.center
                  ? this.props.center
                  : destination && destination.lat && destination.lng
                  ? destination
                  : userLocation
                  ? userLocation
                  : mapOptions.defaultCenter
              }
              zoom={this.props.zoom ? this.props.zoom : mapOptions.defaultZoom}
              resetBoundsOnResize={true}
              options={{ fullscreenControl: false, zoomControl: false }}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) =>
                this.apiIsLoaded(
                  map,
                  maps,
                  markers,
                  destination,
                  onApiLoadedExternal
                )
              }
            >
              {this.children}
              {!this.children &&
                markers &&
                markers.map((marker, key) => (
                  <Marker
                    marker={marker}
                    lat={marker.lat}
                    lng={marker.lng}
                    order={key + 1}
                    key={'Marker' + marker.lat + marker.long + key}
                    isSelected={marker === selectedMarker}
                    isHovered={marker === hoveredMarker}
                    showShippingCosts={showShippingCosts}
                    onClick={e => this.handleMarkerClick(e, marker)}
                    onMouseOver={e => this.handleMarkerMouseOver(e, marker)}
                    onMouseOut={e => this.handleMarkerMouseOut(e, marker)}
                  />
                ))}
              {destination && (
                <Marker
                  marker={destination}
                  lat={destination.lat}
                  lng={destination.lng}
                  order=""
                  isHovered={destination === hoveredMarker}
                  isHome={showShippingCosts}
                  showShippingCosts={showShippingCosts}
                  onClick={e => this.handleMarkerClick(e, destination)}
                  onMouseOver={e => this.handleMarkerMouseOver(e, destination)}
                  onMouseOut={e => this.handleMarkerMouseOut(e, destination)}
                />
              )}
              {showUserLocation && userLocation && !destination && (
                <Marker
                  marker={userLocation}
                  lat={userLocation.lat}
                  lng={userLocation.lng}
                  order=""
                  showShippingCosts={showShippingCosts}
                  isHovered={userLocation === hoveredMarker}
                  onMouseOver={e => this.handleMarkerMouseOver(e, userLocation)}
                  onMouseOut={e => this.handleMarkerMouseOut(e, userLocation)}
                />
              )}
            </GoogleMapReact>
            {showInfoWindow &&
              showInfoWindowInsideMap &&
              markers &&
              markers.length > 0 && (
                <InfoWindow
                  insideMap={true}
                  markers={markers}
                  selectedMarker={selectedMarker}
                  hoveredMarker={hoveredMarker}
                  showShippingCosts={showShippingCosts}
                  props={this.props}
                  handleItemClick={this.handleMarkerClick.bind(this)}
                />
              )}
          </Box>
          {showInfoWindow && !fullWidthMap && !showInfoWindowInsideMap && (
            <Box marginLeft="20px" flex={1}>
              <InfoWindow
                height={this.props.height}
                splitAt={this.props.splitAt}
                markers={markers}
                homeMarker={showShippingCosts ? destination : null}
                selectedMarker={selectedMarker}
                hoveredMarker={hoveredMarker}
                showShippingCosts={showShippingCosts}
                props={this.props}
                handleItemClick={this.handleMarkerClick.bind(this)}
              />
            </Box>
          )}
        </Flex>
        <Hide desktop>
          {showInfoWindow &&
            (isMobile || fullWidthMap) &&
            markers &&
            markers.length > 0 &&
            this.props.chooseLabelText && (
              <Text
                fontSize="16px"
                fontWeight="bold"
                lineHeight="38px"
                letterSpacing="1px"
                color="blues.peacock"
              >
                {this.props.chooseLabelText}
              </Text>
            )}
          {showInfoWindow &&
            (isMobile || fullWidthMap) &&
            markers &&
            markers.length > 0 && (
              <InfoWindow
                height="auto"
                markers={markers}
                selectedMarker={selectedMarker}
                hoveredMarker={hoveredMarker}
                showShippingCosts={showShippingCosts}
                props={this.props}
                handleItemClick={this.handleMarkerClick.bind(this)}
              />
            )}
        </Hide>
      </>
    );
  }
}

export default withTranslation()(Map);
