import { nearestPickupPoints } from './PickupPoints';

const MAX_POINTS_ON_MAP = 10;
const ADDITIONAL_SHIPPING_COST_PER_KILOMETER = 0.5;

// Return map bounds based on list of markers
const getMapBounds = (map, maps, markers, destination) => {
  if (maps != null) {
    const bounds = new maps.LatLngBounds();
    markers.forEach(marker => {
      bounds.extend(new maps.LatLng(marker.lat, marker.lng));
    });
    if (origin) {
      bounds.extend(new maps.LatLng(destination.lat, destination.lng));
    }
    return bounds;
  }
  return null;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  if (maps) {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        if (map) {
          map.fitBounds(bounds);
        }
      });
    });
  }
};

// Fit map to its bounds and bind resize listener
const fitBounds = (map, maps, markers, destination) => {
  if (markers) {
    // Get bounds by our markers
    const bounds = getMapBounds(map, maps, markers, destination);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  }
};

// Update map with SearchBox places
const addPlaces = (
  map,
  maps,
  places,
  destination,
  userLocation,
  updateState
) => {
  if (places != null && places.length > 0) {
    destination = {
      address: places[0].formatted_address,
      lat: places[0].geometry.location.lat(),
      lng: places[0].geometry.location.lng(),
    };
  }

  var pickupPoints = [];
  if (
    destination != null &&
    destination.lat != null &&
    destination.lng != null
  ) {
    pickupPoints = nearestPickupPoints(destination.lat, destination.lng);
  } else if (
    userLocation != null &&
    userLocation.lat != null &&
    userLocation.lng != null
  ) {
    destination = { lat: userLocation.lat, lng: userLocation.lng };
    pickupPoints = nearestPickupPoints(destination.lat, destination.lng);
  }

  if (pickupPoints.length > 0) {
    const markers = pickupPoints.map(pickupPoint => {
      return {
        ...pickupPoint,

        distanceValue: 'n/a',
        distanceText: 'n/a',
      };
    });

    updateMarkersWithDistances(
      map,
      maps,
      markers,
      destination,
      userLocation,
      updateState
    );
  }
};

// Update Markers with calculated distance,
// then keep closest to destination Markers
// and finally update their actual distance
const updateMarkersWithDistances = (
  map,
  maps,
  markers,
  destination,
  userLocation,
  updateState
) => {
  var origin;
  if (
    destination != null &&
    destination.lat != null &&
    destination.lng != null
  ) {
    origin = new maps.LatLng(destination.lat, destination.lng);
  } else if (
    userLocation != null &&
    userLocation.lat != null &&
    userLocation.lng != null
  ) {
    origin = new maps.LatLng(userLocation.lat, userLocation.lng);
  }

  if (markers && origin) {
    // Calculate distance from destination
    var service = new maps.DistanceMatrixService();

    const destinations = markers.map(
      marker => new maps.LatLng(marker.lat, marker.lng)
    );

    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: destinations,
        travelMode: 'DRIVING',
        avoidHighways: false,
        avoidTolls: false,
      },
      (response, status) => {
        if (status === maps.DistanceMatrixStatus.OK) {
          var results = response.rows[0].elements;
          for (var i = 0; i < results.length; i++) {
            var element = results[i];
            markers[i].distanceValue = element.distance.value;
            markers[i].distanceText = element.distance.text;
          }
          // Sort markers by closest to farthest
          markers.sort((a, b) => a.distanceValue - b.distanceValue);
        } else {
          console.error('Unable to calculate distance: ' + status);
        }

        // Limit to MAX_POINTS_ON_MAP based on distance
        if (markers && markers.length > MAX_POINTS_ON_MAP) {
          markers = markers.slice(0, MAX_POINTS_ON_MAP);
        }

        // Update addition shipping cost for filtered markers
        updateMarkersWithAdditionalShippingCost(markers);

        if (updateState) {
          updateState(markers, destination, userLocation);
        }

        fitBounds(map, maps, markers, destination);
      }
    );
  }
};

// Bind search Button click to SearchBox Input events
const search = () => {
  var input = document.getElementById('searchbox-input');
  window.google.maps.event.trigger(input, 'focus', {});
  window.google.maps.event.trigger(input, 'keydown', { keyCode: 13 });
  window.google.maps.event.trigger(this, 'focus', {});
};

const retrievePickupPoint = (boxId, userLocation) => {
  // Find neared PickupPoint to user's location
  // TODO: Implement BoxID tracking logic
  var pickupPoints = nearestPickupPoints(
    userLocation ? userLocation.lat : '38.013965',
    userLocation ? userLocation.lng : '23.827759'
  );
  return (pickupPoints = pickupPoints.slice(0, 1));
};

const calculateAdditionalShippingCost = marker => {
  return (
    (marker.distanceValue * ADDITIONAL_SHIPPING_COST_PER_KILOMETER) /
    1000
  ).toFixed(2);
};

const updateMarkersWithAdditionalShippingCost = markers => {
  markers.map(marker => {
    marker.additionalCost = calculateAdditionalShippingCost(marker);
  });
};

export {
  getMapBounds,
  bindResizeListener,
  fitBounds,
  addPlaces,
  updateMarkersWithDistances,
  search,
  retrievePickupPoint,
  updateMarkersWithAdditionalShippingCost,
};
