import React, { Component } from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const Input = styled.input`
  width: 100%;
  height: 38px;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.lightBlueGrey};
  background-color: #ffffff;
`;

class SearchBox extends Component {
  componentDidMount({ map, maps } = this.props) {
    this.searchBox = new maps.places.SearchBox(this.searchInput);
    this.searchBox.setBounds(map.getBounds());
    this.searchBox.addListener('places_changed', this.onPlacesChanged);
  }

  componentWillUnmount({ maps } = this.props) {
    maps.event.clearInstanceListeners(this.searchInput);
  }

  onPlacesChanged = (
    { map, maps, addPlaces, destination, userLocation, updateState } = this
      .props
  ) => {
    console.log('Places changed!');
    const selectedPlaces = this.searchBox.getPlaces();
    addPlaces(
      map,
      maps,
      selectedPlaces,
      destination,
      userLocation,
      updateState
    );
    this.searchInput.blur();
  };

  render() {
    return (
      <Box mb={['5px', '20px', '20px']}>
        <Input
          id="searchbox-input"
          ref={ref => {
            this.searchInput = ref;
          }}
          type="text"
          defaultValue={this.props.defaultValue || ""}
          onFocus={this.clearSearchBox}
          width="100%"
          placeholder={this.props.searchBoxPlaceholder}
        />
      </Box>
    );
  }
}

export default SearchBox;
